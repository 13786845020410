.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: blue;
}

/* #popover-left:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 10px solid #6A0136;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
} */

#popover-left::before {
  content: "";
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
}

.zc-chat-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

.zc-chat-icon img {
  height: 60px;
}

.zc-chat-trigger {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 12px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

.zc-chat-trigger .icon {
  color: #1e293b; /* Default text color */
}

.zc-chat-window {
  position: absolute;
  bottom: 1px;
  right: 70px;
  width: 256px;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 10000;
}

.zc-chat-window.dark {
  background-color: #1f2937;
  border-color: #4b5563;
  color: #d1d5db;
}

